//initialize colour, typography css properties before anything else
import "@webcomponents/scoped-custom-element-registry";

import "intercooler";
import "../../../../legl-ui/cookie-consent";
import "../../../../legl-ui/lds-button";
import { setCustomColourProperties } from "../../../../legl-ui/lds-colours";
import { setCustomElevationProperties } from "../../../../legl-ui/lds-elevation";
import { setCustomSpacingProperties } from "../../../../legl-ui/lds-spacing";
import { setCustomTypographyProperties } from "../../../../legl-ui/lds-typography";
import "../../../../legl-ui/request-status";
import "../scss/checkout.scss";
import "./checkout.js";
import "./components/accordion/payments_accordion.js";
import "./components/iframe-close-button.js";

setCustomColourProperties();
setCustomSpacingProperties();
setCustomTypographyProperties();
setCustomElevationProperties();

(async () => {
  await import("../../../../legl-ui/payment-wrapper");
  await import("../../../../legl-ui/adyen-component");
  import("./components/pay-stripe-component.js");
})();
